.check {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: $bg-semi-10;
  border: 1px solid $bg-semi-10;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 100%;
    background: $white;
    transition: all 0.3s ease;
  }

  &.checked {
    background: $primary;
    border-color: $primary;

    &:before {
      width: 10px;
      height: 10px;
    }
  }
}

.check.disabled {
  opacity: .2;
}
