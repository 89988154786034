.button-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button-group-item {
    border-width: 1px !important;
    border-style: solid !important;
    &:not(.active) {
      border-color: transparent !important;
    }
  }
}
