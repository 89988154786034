$font-family-sans-serif: system-ui, -apple-system, "SF Pro", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1rem;
$font-size-sm:                $font-size-base * .875;
$font-size-lg:                $font-size-base * 1.25;

$white:    #fff;
$gray-100: #C5CBD5;
$gray-200: #767680;
$gray-300: #6A6A73;
$gray-400: #747474;
$gray-500: #434346;
$gray-600: #3C3C3C;
$gray-700: #303030;
$gray-800: #282828;
$gray-900: #161616;
$black:    #000000;

$blue:          #0000FF;
$indigo:        #4D43B3;
$purple:        #5E5CE6;
$pink:          #d63384;
$red:           #FF5E48;
$red-light:     #EF6B7E;
$dark-red:      #A12D26;
$orange:        #AA5A10;
$yellow:        #F6E543;
$green:         #53F559;
$green-light:   #A7FFAA;
$dark-green:    #16871A;
$brown:         #AA5A10;
$teal:          #00DBFF;
$cyan:          #1C5D6C;
$dark:          #0C0C0C;
$beige:         #D8B57E;
$gold:          #F2B94A;

$primary:       $teal;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $white;
$secondary:     $gray-900;
$silver:        $gray-100;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $teal,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "gray":       $gray-800,
  "gray-light": $gray-600,
  "dark":       $dark,
  "pink":       $pink,
  "cyan":       $cyan,
  "orange":     $orange,
  "blue":       $blue,
  "purple":     $purple,
  "dark-red":   $dark-red,
  "dark-green": $dark-green,
  "beige":      $beige,
  "gold":       $gold,
  "silver":     $silver,
);

$body-bg: $black;
$body-color: $white;

$text-muted:    $gray-400;

$spacer: 1rem;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

//$border-radius: 1.5rem;
//$border-radius-sm: $border-radius * 0.75;
//$border-radius-lg: $border-radius * 1.25;
$border-radius:               .625rem;
$border-radius-sm:            .25rem;
$border-radius-lg:            1rem;
$border-radius-xl:            2rem;
$border-radius-2xl:           3rem;
$border-radius-pill:          50rem;

//$border-width: 2px;
//
//$body-bg: $dark;
//$body-color: $white;
$modal-content-bg: $secondary;
$modal-content-color: $body-color;
$modal-content-border-width: 0;
$modal-content-border-color: $gray-600;
$modal-header-border-width: 1px;
$modal-header-border-color: $gray-600;
$modal-backdrop-bg:                 $black;
$modal-backdrop-opacity:            .9;
$modal-content-border-radius:       $border-radius;
$modal-fade-transform:              translate(0, 100%);
//$modal-header-padding-y: $spacer * 1.5;
//$modal-header-padding-x: $spacer * 2.5;
//$modal-inner-padding: $spacer * 2.5;
//
//$font-weight-lighter:         lighter;
//$font-weight-light:           300;
//$font-weight-normal:          400;
//$font-weight-semibold:        600;
//$font-weight-bold:            700;
//$font-weight-bolder:          800;
//
//$font-weight-base:            $font-weight-semibold;
//
$enable-shadows: false;

//$btn-font-family: Qore;
$btn-border-width: 1px;
$btn-padding-y:         .75rem;
$btn-padding-x:         1.75rem;
$btn-padding-y-lg:      1rem;
$btn-padding-x-lg:      2.25rem;
$btn-close-color: $white;
$btn-font-weight: $font-weight-semibold;
//$btn-border-radius:           $border-radius / 2;
//$btn-border-radius-sm:        $border-radius-sm / 2;
//$btn-border-radius-lg:        $border-radius-lg / 2;
//$input-border-radius:         $border-radius / 2;
//$input-border-radius-sm:      $border-radius-sm / 2;
//$input-border-radius-lg:      $border-radius-lg / 2;
//$input-disabled-bg:           $gray-800;
$input-btn-padding-y:         .75rem;
$input-btn-padding-x:         .75rem;
$input-btn-focus-box-shadow:  none;
$input-bg:                    $secondary;
$input-border-color:          $secondary;
$input-border-width:          0;
$input-group-addon-bg:        $input-bg;
$input-group-addon-color:     $text-muted;

//$global-padding: 1.25rem;

$card-bg: $secondary;
$card-spacer-y: $spacer * 1;
$card-spacer-x: $spacer * 1;
$card-border-width: 0;
$card-border-radius: 10px;
//$card-border-color: $gray-600;
$card-color: $white;
//$card-box-shadow: 0 0 20px -10px rgba($black, .2);
//
//$component-active-color: $dark;
//
//$headings-font-weight: 700;
//
//$accordion-bg: $gray-600;
//$accordion-button-active-bg: $primary;
//$accordion-button-active-color: $dark;
//
//$table-border-color: $dark;
//$table-border-width: 2px;



// Progress bars

// scss-docs-start progress-variables
$progress-height:                   1.25rem;
$progress-bg:                       $dark;
$progress-border-radius:            $border-radius-sm;
$progress-box-shadow:               none;
$progress-bar-color:                $primary;
$progress-bar-bg:                   $gray-300;
// scss-docs-end progress-variables


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px,
);
// scss-docs-end container-max-widths

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-font-size:                $font-size-sm;
$dropdown-color:                    $white;
$dropdown-bg:                       $gray-800;

$dropdown-link-color:               $primary;
$dropdown-link-hover-bg:            darken($primary, 10%);

$dropdown-link-disabled-color:      $gray-700;

$dropdown-header-color:             $gray-700;
// scss-docs-end dropdown-variables


$tooltip-color:                     $gray-800 !default;
$tooltip-bg:                        $white !default;


// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
//$alert-padding-y:               $spacer !default;
//$alert-padding-x:               $spacer !default;
//$alert-margin-bottom:           1rem !default;
//$alert-border-radius:           $border-radius !default;
//$alert-link-font-weight:        $font-weight-bold !default;
$alert-border-width:            0;
$alert-bg-scale:                -12%;
$alert-border-scale:            -70%;
$alert-color-scale:             40%;
//$alert-dismissible-padding-r:   $alert-padding-x * 3 !default; // 3x covers width of x plus default padding on either side
// scss-docs-end alert-variables

// Chains
$eth: #627EEA;
$bsc: #F3BA2F;
$base: #0052FF;
$tron: #FF040B;
$ton: #0088CC;
$solana: #D730FA;
$sui: #4CA3FF;

