// Override default variables before the import
@import "custom-variables";

// Import Bootstrap and its default variables
@import 'bootstrap';

@import 'fonts';

/* ############### UTILITIES/HELPER CLASSES ############### */
@import "util/background";
@import "util/border";
@import "util/gap";
@import "util/height";
@import "util/margin";
@import "util/misc";
@import "util/padding";
@import "util/position";
@import "util/typography";
@import "util/width";

// Pages
@import "components/main-layout";

// Global style
@import 'global';

// Components
@import "react-toggle/style.css";
@import "components/onboarding.scss";
@import "components/overlay";
@import "components/pagination";
@import "components/pincode";
@import "components/badge-icon";
@import "components/chain-label";
@import "components/sidenav";
@import "components/whale-signals/whale-signals";
@import "components/check";
@import "components/tabs";
@import "components/button-group";
@import "components/table-orders";
@import "animations";
