
.shake-explode {
  animation: shake-explode 2s linear;
}

@keyframes shake-explode {
  0% {
    transform: scale(1) rotate(-5deg);
  }
  5% {
    transform: scale(0.975) rotate(5deg);
  }
  10% {
    transform: scale(0.95) rotate(-5deg);
  }
  15% {
    transform: scale(0.925) rotate(5deg);
  }
  20% {
    transform: scale(0.9) rotate(-5deg);
  }
  25% {
    transform: scale(0.875) rotate(5deg);
  }
  30% {
    transform: scale(0.85) rotate(-5deg);
  }
  35% {
    transform: scale(0.825) rotate(5deg);
  }
  40% {
    transform: scale(0.8) rotate(-5deg);
  }
  45% {
    transform: scale(0.775) rotate(5deg);
  }
  50% {
    transform: scale(0.75) rotate(0deg);
  }
  55% {
    transform: scale(0.775) rotate(5deg);
  }
  60% {
    transform: scale(0.75) rotate(-5deg);
  }
  65% {
    transform: scale(0.725) rotate(5deg);
  }
  70% {
    transform: scale(0.7) rotate(-5deg);
  }
  75% {
    transform: scale(0.675) rotate(5deg);
  }
  80% {
    transform: scale(0.65) rotate(-5deg);
  }
  85% {
    transform: scale(0.625) rotate(5deg);
  }
  90% {
    transform: scale(0.6) rotate(-5deg);
  }
  95% {
    transform: scale(1) rotate(0deg);
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
