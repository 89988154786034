.tabs {
  .tabs-nav {
    width: 100%;
    .tabs-nav-item {
      @extend .btn;
      @extend .btn-link;
      text-decoration: none;
      color: $text-muted;
      border-radius: 0;
      padding: ($spacer / 3) $spacer;
      font-size: 14px;
      &.active {
        color: $white;
      }
      &.disabled {
        opacity: 1;
      }
    }
  }
}
